<template>
  <div style="line-height: 1; background-color: #ff7f47">
    <div class="bg">
      <div class="user">
        <div class="user_item">
          <img :src="inviteInfo.avatar" alt="" />
          <p>{{ inviteInfo.user_name }}</p>
        </div>
        <div class="user_item" v-if="userInfo">
          <img :src="userInfo.avatar" alt="" />
          <p>{{ userInfo.user_name }}</p>
        </div>
        <div class="user_item" v-else>
          <div class="add">+</div>
          <p>受邀用户</p>
        </div>
      </div>
      <div class="code_invite" v-clipboard:copy="inviteInfo.invite_code">
        邀请码<span>{{ inviteInfo.invite_code }}</span>
      </div>
      <img
        v-clipboard:copy="inviteInfo.invite_code"
        @click="_golink"
        class="join"
        src="@/assets/red_pocket/tjf_gfhb_yqhy_ljjr_btn.png"
        alt=""
      />
    </div>
    <van-overlay :show="show" @click="show = false">
      <self-mask></self-mask>
    </van-overlay>
  </div>
</template>

<script>
import {
  InvitationGetInviteUserInfo,
  WechatCenterGetWebUser,
} from "@/api/red_pocket.js";
import {
  isMicroMessenger,
  isAndroid,
  isIOS,
  isMobile,
} from "@/utils/user-agent";
import Vue from "vue";
import VueClipboards from "vue-clipboards";
import { Progress } from "vant";
import { Overlay } from "vant";
import SelfMask from "@/views/alipage/ToTaoBao";
Vue.use(Progress).use(VueClipboards).use(Overlay);
export default {
  components: {
    SelfMask,
  },

  data() {
    return {
      inviteInfo: {},
      userInfo: null,
      show: false,
    };
  },

  created() {
    this.enterWxAuthor();
  },

  mounted() {
    document
      .querySelector("body")
      .setAttribute("style", "background-color:#FF7F47");
  },
  beforeDestroy() {
    document.querySelector("body").removeAttribute("style");
  },

  methods: {
    _golink() {
      if (isIOS) {
        var ios_link =
          "https://apps.apple.com/cn/app/%E6%B7%98%E8%A1%97%E5%9D%8A/id1444751745";
        window.location.href = ios_link;
        return "ios";
      } else {
        // 安卓微信浏览器需要在默认浏览器打开
        // if (isMicroMessenger) {
        //   this.show = true;
        //   return false;
        // }

        var andriod_link =
          "https://a.app.qq.com/o/simple.jsp?pkgname=com.quanminhuyu.taojiefang";
        // "https://a.app.qq.com/o/simple.jsp?pkgname=com.quanminhuyu.taojiefang";
        window.location.href = andriod_link;
        return "andriod";
      }
    },

    //获取邀请用户信息
    InvitationGetInviteUserInfo(inviteCode) {
      InvitationGetInviteUserInfo({
        inviteCode,
      }).then((res) => {
        if (res.errCode == 0) {
          this.inviteInfo = res.data;
          this.WechatCenterGetWebUser(this.$route.query.code);
        }
      });
    },

    //获取用户信息
    WechatCenterGetWebUser(code) {
      WechatCenterGetWebUser({
        code,
      }).then((res) => {
        if (res.errCode == 0) {
          this.userInfo =
            this.inviteInfo.user_name == res.data.user_name ? null : res.data;
        }
      });
    },

    //提取code
    getUrlParam(name) {
      //构造一个含有目标参数的正则表达式对象
      return (
        decodeURIComponent(
          (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
            location.href
          ) || [, ""])[1].replace(/\+/g, "%20")
        ) || null
      );
    },

    //微信授权
    enterWxAuthor() {
      if (this.getUrlParam("code")) {
        this.InvitationGetInviteUserInfo(this.$route.query.state);
      } else {
        const REDIRECT_URI = encodeURIComponent(
          window.location.protocol +
            "//" +
            window.location.host +
            "/red-pocket/aaa"
        );
        //没有微信用户信息，没有授权-->> 需要授权，跳转授权页面
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx6d025d1cd025569d&redirect_uri=${REDIRECT_URI}&response_type=code&scope=snsapi_userinfo&state=${this.$route.params.code}#wechat_redirect`;
      }
    },
  },
};
</script>

<style lang="less">
.bg {
  width: 100%;
  height: 637px;
  background: url("../../assets/red_pocket/tjf_gfhb_yqhy_bg_img-min.png")
    no-repeat;
  background-size: 100% 100%;
  padding: 0 82px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  .user {
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
    box-sizing: border-box;
    width: 100%;
    &_item {
      text-align: center;
      position: relative;
      img {
        width: 58px;
        height: 58px;
        border-radius: 50%;
        border: 2px solid #fff0d3;
      }
      .add {
        text-align: center;
        line-height: 50px;
        width: 58px;
        font-size: 40px;
        background: #ff7f47;
        color: #fff;
        height: 58px;
        border-radius: 50%;
        border: 2px solid #fff0d3;
      }
      p {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 82px;
        margin-top: 11px;
        font-size: 13px;
        font-weight: 400;
        color: #fff1d6;
      }
    }
  }
}
.code_invite {
  margin: 43px auto 0;
  width: 210px;
  height: 37px;
  background: #fff0d3;
  border-radius: 37px;
  font-size: 16px;
  font-weight: 600;
  color: #ff4a00;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    font-size: 22px;
    margin-left: 10px;
  }
}

.join {
  width: 195px;
  height: 56px;
  margin: 21px 0;
}
</style>